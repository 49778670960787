<template>
    <div class="admin-create-rota-date">
        <loading-screen :is-loading="isSaving"></loading-screen>

        <!-- Page Header -->
        <div class="page-header">
            <h1 class="page-title">Add Rota Date</h1>
            <div class="actions">
                <a-button class="button-margin-left btn-rounded" icon="arrow-left" size="large" type="primary"
                    @click.prevent="goBack">Back</a-button>
            </div>
        </div>
        <!-- / Page Header -->

        <!-- Is Loading -->
        <div v-if="isLoading" class="loader">
            <a-spin></a-spin>
        </div>
        <!-- / Is Loading -->

        <!-- Form -->
        <div v-if="!isLoading" class="form">
            <a-form-item :label="`Please enter a date in ${firstWeekLabel}`">
                <a-date-picker style="width: 100%" size="large" v-model="selectedDate" />
            </a-form-item>
        </div>
        <!-- / Form -->

        <a-button @click.prevent="save" class="btn-rounded save-button" size="large" type="primary">Save</a-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingScreen from '../../../components/LoadingScreen.vue';
import moment from 'moment';
import rotaDates from '../../../api/rota-dates';
export default {
    components: { LoadingScreen },
    data() {
        return {
            isSaving: false,
            selectedDate: null
        }
    },
    computed: {
        ...mapGetters('admin', {
            selectedOrganisation: 'selectedOrganisation',
            tenantId: 'tenantId',
            isLoading: 'isLoading'
        }),

        timetableWeeksAlias() {
            if (this.selectedOrganisation['content']['$v']['timetableWeeksAlias']) {
                return this.selectedOrganisation['content']['$v']['timetableWeeksAlias'].split(',');
            }
            return ['Week 1'];
        },

        firstWeekLabel() {
            return this.timetableWeeksAlias[0];
        }
    },
    created() {
        if (!this.selectedOrganisation) {
            this.$message.info("Please select an organisation");
            this.$router.push("/admin");
        }
    },
    methods: {
        goBack() {
            this.$router.push("/admin/rota-dates");
        },

        save() {
            if (!this.selectedDate) {
                this.$message.error('Please select a date in ' + this.firstWeekLabel);
                return false;
            }

            let vm = this;
            vm.isSaving = true;

            let params = {
                OwnerId: this.selectedOrganisation.id,
                TimetableStartDate: moment(this.selectedDate).startOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]"),
            };

            rotaDates.addTimetableDate(
                this.tenantId,
                params
            ).then(() => {
                vm.isSaving = false;
                vm.$message.success('Timetable date added successfully');
                vm.goBack();
            }).catch(e => {
                console.log(e);
                vm.$message.error('Error adding timetable date. Please try again.');
                vm.isSaving = false;
            });
        }
    }
}
</script>

<style scoped lang="scss">
.admin-create-rota-date {
    padding-bottom: 40px;

    .loader {
        background: #fff;
        padding: 50px;
        text-align: center;
    }

    .form {
        background: #fff;
        padding: 20px;
        border-radius: 6px;
        font-weight: 500;
        border: 1px solid #fff;
    }

    .button-margin-left {
        margin-left: 10px;
    }

    .save-button {
        margin-top: 20px;
    }
}
</style>